import LogoutHelper from "./LogoutHelper";

let token = window.sessionStorage.getItem("token");

if (token === null) {
    token = 0;
}

export const OneCallBack = (url, _callback = null) => {
    fetch(url + "/" + token).then(response => response.json())
        .then(json => {
            LogoutHelper(json);
            if (typeof (_callback) === "function") {
                _callback(json);
            } else {
                return json;
            }
        }).catch(function (error) {
            console.error(error);
            LogoutHelper("logout");
        });
};

export const OneCallBackNoKey = (url, _callback = null) => {
    fetch(url).then(response => response.json())
        .then(json => {
            LogoutHelper(json);
            if (typeof (_callback) === "function") {
                _callback(json);
            } else {
                return json;
            }
        }).catch(function (error) {
            console.error(error);
            LogoutHelper("logout");
        });
};

export const OneCallBackPage = (url, _callback = null) => {
    fetch(url).then(response => response.json())
        .then(json => {
            LogoutHelper(json);
            if (typeof (_callback) === "function") {
                _callback(json);
            } else {
                return json;
            }
        }).catch(function (error) {
            console.error(error);
            LogoutHelper("logout");
        });
};

export const OneCallBackParam = (url, param = null, _callback = null) => {
    fetch(url + "/" + token).then(response => response.json())
        .then(json => {
            LogoutHelper(json);
            let return_data = {};
            if (typeof param !== "undefined" & param !== null) {
                return_data = json[param];
            } else {
                return_data = json;
            }
            if (typeof (_callback) === "function") {
                _callback(return_data);
            } else {
                return return_data;
            }
        }).catch(function (error) {
            console.error(error);
            LogoutHelper("logout");
        });
};

export const PostDatasetCallback = (url, dataset, _callback = null) => {
    var request = new XMLHttpRequest();
    request.addEventListener("load", () => {
        LogoutHelper(request.response);
        if (typeof _callback === "function") {
            _callback(request.response);
        }
    });
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader("Authorization", "Bearer " + token);
    request.send(JSON.stringify(dataset));
};

export const PostDatasetCallbackJson = (url, dataset, _callback = null) => {
    var request = new XMLHttpRequest();
    request.addEventListener("load", () => {
        LogoutHelper(request.response);
        if (typeof (_callback) === "function") {
            _callback(JSON.parse(request.response));
        }
    });
    request.open("POST", url);
    request.setRequestHeader("Content-Type", "application/json");
    request.setRequestHeader("Authorization", "Bearer " + token);
    request.send(JSON.stringify(dataset));
};